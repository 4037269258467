import React from 'react';
import { useLocation } from 'react-router-dom';
import MainNavigation from './MainNavigation';
import SettingsNavigationProvider from './SettingsNavigationProvider';

const NavigationProvider = () => {
  const { pathname } = useLocation();
  const isSettings = pathname.startsWith('/settings');
  const isIntegrations = pathname.startsWith('/integrations');

  if (isSettings || isIntegrations) {
    return <SettingsNavigationProvider />;
  }

  return <MainNavigation />;
};

export default NavigationProvider;
